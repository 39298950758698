import React from 'react'
import './News3.css'
import '../../Youtube/YtOne'
import Header3 from '../../Headers/Header3';
import Footer from '../../Footer';
import Btn from '../../Buttons/Btn';
// import YtOne from '../../Youtube/YtOne';

function News2() {
  return (
      <div>
      <Header3/>    
      <div className='news1-containering'>
          <img src="\Img\News\Newsheader 3.jpg" alt="Community Management Logo" className="news1-imgs" />
            <div className='div-article'>
              <h1 className='news1-header'>
                Riot Games partners with Estudyante Esports to offer VALORANT scholarship
              </h1>
              <p className='date-header'>
                October 10, 2024 | By from the wires
              </p>
              <p className='news1-body'>
                REINFORCING THEIR commitment to elevating the competitive gaming and esports scene for local grassroots gaming communities in the country, 
                Riot Games Philippines today announced that VALORANT has partnered with Estudyante Esports in support of their nationwide campus league.
              </p>

              <p className='news1-body'>
                In addition to a cash prize pool, VALORANT will also be covering one full semester of tuition for the winning school team–amounting to a 
                combined prize value of nearly 1 million pesos. Organized by Dark League Studios–the official tournament partner of the recently concluded 
                University Athletic Association of the Philippines (UAAP) Esports tournament–and poised to be the biggest campus esports event in the country, 
                the Estudyante Esports campus league welcomes gamers from colleges and universities nationwide.
              </p>

              <img src="\Img\News\riotscholar.jpg" alt="Community Management Logo" className="news2-imgs" />

              <p className='news1-body'>
                The tournament includes teams hailing from schools from the UAAP and the National Collegiate Athletic Association (NCAA), and will feature popular game titles including Riot Games’ VALORANT and League of Legends (LoL).
              </p>

              <p className='news1-body'>
                Exclusive to Estudyante Esports’ VALORANT competition, Riot Games Philippines sweetens the cash prize pot with a scholarship up for grabs. The champion team will receive Php 250,000 cash prize, and the grand finals' Most Valuable Player (MVP) will bring home Php 20,000.
              </p>
              

              <p className='news1-body'>
              “Itong Estudyante Esports, ito yung programa namin na itinatag (this is a program that we built), to be able to make sure that we bring esports to the different schools and universties nationwide, and 
              to provide an avenue for the passions and dreams of all students to really fulfill their dreams, not only in esports, but also in terms of creating careers,” he added.
              </p>
              
              {/* <YtOne/> */}

              <p className='news2-body'>
                The tournament’s first runner-up will receive Php 100,000, and both the champion’s and runner-up’s school/campus esports organizations will receive cash support of Php 80,000 and Php 50,000, respectively.
              </p>

              <p className='news1-body'>
                “That’s what campus esports is all about, bringing all generations together in making sure that you guys are well represented,” Bayani said.
              </p>

              <p className='news1-body'>
              Additionally, all members of the VALORANT champion team will be recognized as EE-Scholar Agents, each receiving a scholarship for one full-semester in their respective schools, courtesy of VALORANT.
              </p>
              <Btn/>
            </div>
      </div>
      <Footer/>
      </div>
  )
}

export default News2