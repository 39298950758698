import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import './Career.css';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} hove-arrow`}
      style={{ ...style, display: 'block', right: '10px' }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} hove-arrow`}
      style={{ ...style, display: 'block', left: '10px' }}
      onClick={onClick}
    />
  );
};

function Hove() {
  const settings = {
    autoplay: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    loop: true,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
          loop: true,
        }
      }
    ]
  };

  return (
    <div id='career' className='voh1'>
      <h1 className='history1'>
        <span style={{fontFamily:'bai-light'}}>WORK </span>WITH US
      </h1>
    <Container className='hove-cont'>
      <Slider {...settings}>
      <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\Img\News\league-ops.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>PROJECT MANAGEMENT</span>
            <p className="card-content">
            Esports project management coordinates teams, schedules, and resources to ensure smooth event execution. It balances technical needs, 
            player logistics, and marketing strategies in a fast-paced environment. Clear communication and quick decisions are key to success.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">JOIN</span>
            <span className="date">US</span>
          </div>
        </div>
        
        <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\Img\News\creatives.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>CREATIVES</span>
            <p className="card-content">
            Dark League Studios seeks creative talent to design visual content for games and marketing. You'll collaborate with cross-functional teams to deliver consistent, 
            high-quality assets across platforms. Ideal candidates are skilled in design, innovative, and passionate about gaming.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">JOIN</span>
            <span className="date">US</span>
          </div>
        </div>

      <div className="card">
          <img className='ev-img'
            width="100%"
            height="auto"
            src="\Img\News\league-ops.png"
            alt="Company Logo"
          />
          <div className="content-box">
            <span className="card-title" style={{fontSize:'20px'}}>LEAGUE OPERATIONS</span>
            <p className="card-content">
            The League Operations role at Dark League Studios manages esports tournaments, ensuring smooth execution, rule compliance, and schedule coordination.
            The candidate will work with teams and players to maintain competitive integrity. Strong organizational and esports knowledge is essential.
            </p>
            <span className="see-more">
            {/* <a href="/news1">
              <button class="button">
                  <span class="button_lg">
                      <span class="button_sl"></span>
                      <span class="button_text" style={{fontFamily:'monument'}}>See More</span>
                  </span>
              </button>
            </a> */}
          </span>
          </div>
          <div className="date-box">
            <span className="month">JOIN</span>
            <span className="date">US</span>
          </div>
        </div>
      </Slider>
    </Container>
    </div>
  );
}

export default Hove;
