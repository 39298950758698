import React from 'react';
import './News.css';  // Import the CSS file

const CompanyNews = () => {
  return (
    <div id='news' className="news-container">
       <h1 className='news-header'>
        <span style={{ fontFamily: 'bai-light' }}>WHAT'S</span> HAPPENING?
      </h1>
      <div className="news-item">
      <div><img src="\Img\News\Thumbnail3.png" alt="Community Management Logo" className="news-imgs" /></div>
        <div className="text-container">
          <h2 className="news-title">Estudyante Esports to partner with PBA for biggest collegiate esports league in PH</h2>
          <span className='news-date'>
            October 14, 2024, 9:10 pm | By Caezar Julius Cortez
          </span>
          <p className="news-description">
            WITH the Philippine Basketball Association as its official tournament partner, Dark League Studios recently introduced 
            Estudyante Esports, <span className='cut'>the largest, most extensive and all-inclusive inter-school esports competition in the country to date.
            From the organizers of the recently concluded UAAP Esports Competition comes The National Championships, where it will feature 4 esports titles: 
            Mobile Legends: Bang Bang, Valorant, League of Legends and Tekken 8.</span>
          </p>
          <a href = '/news1'>
            <button className="news-button">See More</button>
          </a>
        </div>
      </div>

      <div className="news-item">
      <div><img src="\Img\News\Thumbnail2.png" alt="Community Management Logo" className="news-imgs" /></div>
        <div className="text-container">
          <h2 className="news-title">Riot Games partners with Estudyante Esports to offer VALORANT scholarship</h2>
          <span className='news-date'>
            October 10, 2024 | By from the wires
          </span>
          <p className="news-description">
          REINFORCING THEIR commitment to elevating the competitive gaming and esports scene for local grassroots gaming communities in the country,<span className='cut'> 
          Riot Games Philippines  today announced that VALORANT has partnered with Estudyante Esports in support of their nationwide campus league. 
          In addition to a cash prize pool, VALORANT will also be covering one full semester of tuition for the winning school team–amounting to a 
          combined prize value of nearly 1 million pesos.</span>
          </p>
          <a href = '/news2'>
            <button className="news-button">See More</button>
          </a>
        </div>
      </div>

      <div className="news-item">
      <div><img src="\Img\News\Thumbnail1.png" alt="Community Management Logo" className="news-imgs" /></div>
        <div className="text-container">
          <h2 className="news-title">UAAP's venture into esports aims to promote responsible gaming</h2>
          <span className='news-date'>
            August 7, 2024 | Carlos Pineda
          </span>
          <p className="news-description">
            ESPORTS HAS slowly inched its way to become a competitive avenue in collegiate sports as seen from events held by AcadArena,<span className='cut'>
            Dark League Studios, and Collegiate Center for Esports. But with the UAAP officially incorporating esports in its list of events, 
            the push for gaming to be a part of a school's program can lead to numerous opportunities especially for students. It was Cignal TV 
            who pushed for esports to be included and they contacted Dark League Studios.</span> 
          </p>
          <a href = '/news3'>
            <button className="news-button">See More</button>
          </a>
        </div>
      </div>

      <div className="news-item">
      <div><img src="\Img\News\uaap-1920.png" alt="Community Management Logo" className="news-imgs" /></div>
        <div className="text-container">
          <h2 className="news-title">Dark League Studios’ CEO Talks About Sustainability in Esports and Support From UAAP</h2>
          <span className='news-date'>
            August 15, 2024 | Ramon Domingo
          </span>
          <p className="news-description">
          As one of the partners behind UAAP esports, Dark League Studios knows a thing or two about running grassroots events. <span className='cut'>CEO AC 
          Valdenor has helped launch esports events that highlight local and academic communities. All of them have the goal of not just 
          promoting esports but building it into a sustainable industry. Dark League Studios’ role in handling UAAP esports is vital as this is 
          their chance to show people from all generations the influence and importance of esports.</span> 
          </p>
          <a href = '/news4'>
            <button className="news-button">See More</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CompanyNews;
