import React from 'react'
import './News1.css'
import '../../Youtube/YtOne'
import Header3 from '../../Headers/Header3';
import Footer from '../../Footer';
import YtTwo from '../../Youtube/YtTwo';
import Btn from '../../Buttons/Btn';

function News1() {
  return (
      <div>
      <Header3/>    
      <div className='news1-containering'>
          <img src="\Img\News\Newsheader2.jpg" alt="Community Management Logo" className="news1-imgs" />
            <div className='div-article'>
              <h1 className='news1-header'>
                Estudyante Esports to partner with PBA for biggest collegiate esports league in PH
              </h1>
              <p className='date-header'>
                October 14, 2024, 9:10 pm | By Caezar Julius Cortez
              </p>
              <p className='news1-body'>
              WITH the Philippine Basketball Association as its official tournament partner, Dark League Studios recently introduced Estudyante Esports, 
              the largest, most extensive and all-inclusive inter-school esports competition in the country to date. From the organizers of the recently 
              concluded UAAP Esports Competition comes The National Championships, where it will feature 4 esports titles: Mobile Legends: Bang Bang, Valorant, 
              League of Legends and Tekken 8. The top teams that will reach the finals across the four titles will have the opportunity to compete in Manila on 
              February 22 and 23, for a total prize pool of P1.6 million, with Riot Games, covering the P500,000 prize pool plus a full semester of tuition for the winning team in Valorant.
              </p>

              <img src="\Img\News\Newsimage2.png" alt="Community Management Logo" className="news2-imgs" />

              <p className='news1-body'>
              Program director of Estudyante Esports Alexander Kibanoff shared his thoughts on how the tournament would impact the national esports scene in a recent press conference.
              </p>

              <p className='news1-body'>
                “The National Championship seeks to promote fun, high-spirited, healthy esports competitions in order to find the best esports campus teams and individual players across the country,” Kibanoff said.
              </p>
              

              <p className='news1-body'>
              “Itong Estudyante Esports, ito yung programa namin na itinatag (this is a program that we built), to be able to make sure that we bring esports to the different schools and universties nationwide, and 
              to provide an avenue for the passions and dreams of all students to really fulfill their dreams, not only in esports, but also in terms of creating careers,” he added.
              </p>
              
              <YtTwo/>

              <p className='news2-body'>
              Wrexler Bayani, marketing head for Estudyante Esports, also further emphasized that the tournament is there to support the growth and development of PH esports through campus esports.
              </p>

              <p className='news1-body'>
                “That’s what campus esports is all about, bringing all generations together in making sure that you guys are well represented,” Bayani said.
              </p>

              <p className='news1-body'>
                Representatives from PCCR, TUP-Cavite, JRU, UP Diliman, UST were also present during the event.
              </p> 
              <Btn/>             
            </div>
      </div>
      <Footer/>
      </div>
  )
}

export default News1