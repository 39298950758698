import React from 'react'
import './Btn.css'

function Btn() {
  return (
    <div className='btn-div'>
        <a class="fancy" href="/#news">
            <span class="top-key"></span>
            <span class="text">Return</span>
            <span class="bottom-key-1"></span>
            <span class="bottom-key-2"></span>
        </a>
    </div>
  )
}

export default Btn
