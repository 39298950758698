import React from 'react'
import './News4.css'
import '../../Youtube/YtOne'
import Header3 from '../../Headers/Header3';
import Footer from '../../Footer';
import Btn from '../../Buttons/Btn';

function News4() {
  return (
      <div>
      <Header3/>    
      <div className='news1-containering'>
          <img src="\Img\News\uaap-header.png" alt="Community Management Logo" className="news1-imgs" />
            <div className='div-article'>
              <h1 className='news1-header'>
                Dark League Studios’ CEO Talks About Sustainability in Esports and Support From UAAP
              </h1>
              <p className='date-header'>
                August 15, 2024 | Ramon Domingo
              </p>
              <p className='news1-body'>
              As one of the partners behind UAAP esports, Dark League Studios knows a thing or two about running grassroots events. 
              CEO AC Valdenor has helped launch esports events that highlight local and academic communities. All of them have the 
              goal of not just promoting esports but building it into a sustainable industry..
              </p>

              <p className='news1-body'>
              With all that being said, reaching that goal is another thing entirely. When asked what he thinks  is the way forward to 
              reach that goal, AC said, “For esports to be really sustainable here in the Philippines, the older generation should really support it.”"
              </p>

              <img src="\Img\News\uaap2.png" alt="Community Management Logo" className="news2-imgs" />

              <p className='news1-body'>
                Obviously, convincing the older guard is not an easy task, especially when it’s difficult for them to understand esports.
              </p>

              <p className='news1-body'>
              “When I was first presenting the plans for PBA esports I had to relay it to their apos to help convince them to support it,” 
              said AC with an amused smile. Still, these are the movers and shakers of the Philippines, and AC believes that their insight 
              and guidance are vital for the growth of esports.
              </p>
              
              <img src="\Img\News\uaapp.jpg" alt="Community Management Logo" className="news2-imgs" />

              <p className='news2-body'>
              “Even though it’s for the younger generation, esports is still a business and a contribution to our country. That’s why it’s getting bigger and more people are getting involved.”
              </p>

              <p className='news2-body'>
              “Imagine if the schools actually support this,” continued AC. “We’re gamers already, even my children play games, but if they have a 
              program in the school, such as guidelines for responsible gaming, or addressing mental health issues in terms of gaming, you’re not 
              just supporting gaming but also opening opportunities to have a career in gaming and it’s coming from [the older] generation.”
              </p>
              <Btn/> 
            </div>
      </div>
      <Footer/>
      </div>
  )
}

export default News4