import React from 'react';
import Footer from '../Footer';
// import Header from '../Header';
import ImageList from '../ImageList/ImageList';
// import Projects from '../Projects';
// import Events from '../Tiles/Events';
// import Contents from '../Contents';
// import Count from '../Count';
import './home.css';
import Slider from '../Slider';
import Hove from '../Hove/Hove';
import MainH from '../MainHero/MainH';
import Who from '../Who/Who';
import Header3 from '../Headers/Header3';
import CompanyPartners from '../CompanyPartners';
import Test from '../Test/Test';
import Contact from '../Contact/Contact';
import Smart from '../SGA/Smart';
import Estudyante from '../Estudyante/Estudyante';
import Pbae from '../PBA/Pbae';
import Count from '../Count';
import Core from '../Cores/Core';
import News from '../News/News';
import Career from '../Career/Career';

export default function Home() {
  return (
    <div className="home-div">
      <Header3/>
      <MainH/>  
      <News/>    
      <Who/>
      <Core/>
      <Count/>
      <CompanyPartners/>
      <Smart/>
      <Estudyante/>
      <Pbae/>     
      <Slider/> 
      <ImageList/>
      <Hove/>
      <Test/>
      <Career/>
      <Contact/>
      <Footer />
    </div>
  );
}
