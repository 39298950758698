import React from 'react'
import './News3.css'
import '../../Youtube/YtOne'
import Header3 from '../../Headers/Header3';
import Footer from '../../Footer';
import Btn from '../../Buttons/Btn';

function News3() {
  return (
      <div>
      <Header3/>    
      <div className='news1-containering'>
          <img src="\Img\News\Newsheader1.jpg" alt="Community Management Logo" className="news1-imgs" />
            <div className='div-article'>
              <h1 className='news1-header'>
                UAAP's venture into esports aims to promote responsible gaming
              </h1>
              <p className='date-header'>
                Aug 7, 2024 | Carlos Pineda
              </p>
              <p className='news1-body'>
                ESPORTS HAS slowly inched its way to become a competitive avenue in collegiate sports as seen from events held by AcadArena, Dark League Studios, 
                and Collegiate Center for Esports. But with the UAAP officially incorporating esports in its list of events, the push for gaming to be a part of a 
                school's program can lead to numerous opportunities especially for students.
              </p>

              <img src="\Img\News\Newsimage1.png" alt="Community Management Logo" className="news2-imgs" />

              <p className='news1-body'>
                "Big schools are supporting it, big schools are getting into programs to ensure that it gives the right message to the students, that we're doing UAAP, 
                to support their passion into joining tournaments, and to educate them on responsible gaming as well."
              </p>

              <p className='news1-body'>
                It was Cignal TV who pushed for esports to be included and they contacted Dark League Studios, an organization that promotes esports in the academe through 
                their programs like Estudyante Esports, to run the show.
              </p>

              <p className='news1-body'>
                Now with these parties involved, a lot is at stake. Esports is currently a debutant in the UAAP, serving as a demonstration event. Given its status all eyes will surely be on the board.
              </p>
              
              <img src="\Img\News\uaapres.jpg" alt="Community Management Logo" className="news2-imgs" />

              <p className='news2-body'>
              "We are setting the expectations for people that we are prepared for this talaga. People are not sleeping anymore for the last two weeks for the preparation of 
              UAAP esports to make sure that it's going to be fun, exciting, and it's going to be memorable for being the first UAAP esports that we're having in the Philippines," reflected Valdenor.
              </p>
              <Btn/> 
            </div>
      </div>
      <Footer/>
      </div>
  )
}

export default News3