import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './Test.css';

function Test() {
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({ title: "", image: "", image2: "", image3: "", image4: "", description: "" });

  const handleClickOpen = (title, image, image2, image3, image4, description) => {
    setDialogContent({ title, image, image2, image3, image4, description });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div id='services' className='serv'>
      <h1 className='vices'>
        <span style={{ fontFamily: 'bai-light' }}>OUR</span> SERVICES
      </h1>
      <p className='def'>
        We offer a host of services across the following areas
      </p>
      <div className='res'>
        <div className="ccard" onClick={() => handleClickOpen("Broadcast and Production", "/Img/Serve/Servecover/esports broadcast.jpg", "/Img/Serve/Broadcast/bbrod2.png","/Img/Serve/Broadcast/DSC07776.png","/Img/Serve/Broadcast/bbrod1.png",
             "Dark League Studios’ broadcast and production services deliver unparalleled live streaming and televised presentations of competitive gaming events, designed to captivate local and global audiences. We specialize in capturing every thrilling Esports moment of gameplay, expert commentary, insightful analysis, and engaging content that transforms viewers' experiences into immersive spectacles akin to traditional sports broadcasts. Our comprehensive production service encompasses cutting-edge technology and creative expertise, including seamless camera operations, precise video switching, pristine audio management, captivating graphics creation and integration, thorough directing, and meticulous coordination. We ensure flawless execution, elevating esports tournaments and events to new heights and guaranteeing an unforgettable Esports viewing experience for the Filipinos and fans worldwide.")}>
          <img src="/Img/Serve/NewServe/esports gaming.png" alt="Broadcast Logo" className="ponsive-imgs" />
          <div className="overlay">
            <div className="text">Esports-gaming Broadcast and Production</div>
          </div>
          <p className='out-desc'>
            Equipped with advanced technology and amenities, it offers an intimate and highly engaging setting for tournaments, live broadcasts, and other major occasions.
          </p>
        </div>
        <div className="ccard" onClick={() => handleClickOpen("Events Management", "/Img/Serve/Servecover/tournament community management.jpg", "/Img/Serve/Events/eevent1.png","/Img/Serve/Events/eve3.png","/Img/Serve/Events/Group 3.png",
             "At Dark League Studios, we specialize in creating unforgettable gaming and esports events, offering top-tier event management services that set the industry standard. Our team transforms ideas into spectacular experiences, from securing world-class venues and coordinating with top-tier gaming teams to managing sponsors and vendors. We offer a comprehensive suite of services, including logistics management, live broadcast coordination, vendor relations, and audience engagement strategies, ensuring that your event not only meets but exceeds expectations, delivering a flawless and memorable experience for attendees and viewers alike. Creating opportunities for your brand to shine and build strong connections within the gaming community and beyond. Contact Dark League Studios today, and let's create an unforgettable experience together.")}>
          <img src="/Img/Serve/NewServe/Tournament Community.png" alt="Events Logo" className="ponsive-imgs" />
          <div className="overlay">
            <div className="text">Tournaments and Community Management</div>
          </div>
          <p className='out-desc'>
           Create a memorable experience for participants and viewers as our team meets your expectations in both live and online event production, ensuring that your esports events are executed flawlessly.
          </p>
        </div>
        <div className="ccard" onClick={() => handleClickOpen("Esports Marketing Services", "/Img/Serve/Servecover/conventions.jpg", "/Img/Serve/marketing/mar2.png","/Img/Serve/marketing/mar3.png","/Img/Serve/marketing/Group 4.png",
             "Immerse yourself in an ultimate fan experience with our expertly curated pop culture conventions. From exclusive panels, engaging venue designs, and meet-and-greets to interactive exhibits and merchandise; Dark League Studios bring fandoms and festivities together to experience best out of esports, gaming, live music, pop culture, and entertainment to life. Each convention is a carefully curated celebration designed to delight fans of all ages and interests, offering unforgettable experiences and connections within the pop culture community. Our way of interaction is what we call “a sensational experience of excitement” with diverse content reveals, interactive fan experiences, and immersive themed zones that bring your favorite universes to life. Engage in hands-on workshops, explore artist alleys filled with unique collectibles, and tune in on epic live performances and screenings.")}>
          <img src="\Img\Serve\conventions.png" alt="Marketing Logo" className="ponsive-imgs" />
          <div className="overlay">
            <div className="text">Pop Culture Conventions</div>
          </div>
          <p className='out-desc'>
          Immerse yourself in an ultimate fan experience with our expertly curated pop culture conventions. Dark League Studios bring the best of esports, gaming, and entertainment to life.
          </p>
        </div>
      </div>
      <div className='res1'>
        <div className="ccard" onClick={() => handleClickOpen("Esports Marketing Services", "/Img/Serve/Servecover/web3.jpg", "/Img/Serve/web31.png","/Img/Serve/web32.png","/Img/Serve/web33.png",
             "Revolutionize your brand and embrace the future of digital interaction with Dark League Studios’ cutting-edge Web3 solutions and engagements. We specialize in creating impactful partnerships and activations that leverage blockchain technology, NFTs, and decentralized platforms to enhance user education, engagement, and growth in the Web3 ecosystem. Imagine launching your own cryptocurrency, hosting exclusive virtual events, or gamifying customer interactions with blockchain-based rewards. Our team excels in crafting unique experiences that leverage blockchain technology and decentralized networks to forge deeper connections with your audience. We will also guide you through every step, from strategy development to implementation, ensuring your Web3 initiatives are innovative, impactful, and perfectly aligned with your brand’s vision.")}>
          <img src="/Img/Serve/Servecover/Web Partnerships.png" alt="Marketing Logo" className="ponsive-imgs" />
          <div className="overlay">
            <div className="text">Web3 Partnerships Activations</div>
          </div>
          <p className='out-desc'>
          Dark League Studios’ specialize in creating impactful partnerships and activations that leverage esports, and decentralized platforms to enhance user education, engagement, and growth.
          </p>
        </div>
        <div className="ccard" onClick={() => handleClickOpen("Community Management", "/Img/Serve/Servecover/gaming app dev.jpg", "/Img/Serve/app1.png","/Img/Serve/app2.png","/Img/Serve/app3.png",
             "Transform visions into reality with our top-tier gaming app development services. With the combined effort and creativity of our partners, our technical expertise to craft immersive, high-quality digital activities to captivate users to set new standards of accessibility for audiences everywhere. Here at Dark League Studios, we transform your ideas into blockbuster hits with sophisticated game mechanics, stunning graphics, and captivating storylines. Whether you’re aiming for a high-energy multiplayer experience or a serene single-player adventure, our development team utilizes the latest technology to deliver smooth, engaging gameplay. From VR and AR integrations to AI-driven tools to help improve your efficiency, we provide a comprehensive development experience that turns your creative concepts into industry-leading platforms.")}>
          <img src="/Img/Serve/NewServe/Gaming App Development.png" alt="Community Management Logo" className="ponsive-imgs" />
          <div className="overlay">
            <div className="text">Gaming App Development</div>
          </div>
          <p className='out-desc'>
          Transform visions into reality with our top-tier gaming app development services. Our technical expertise to craft immersive, high-quality games platforms captivate users to set new standards of accessibility.
          </p>
        </div>
      </div>
      
      <Dialog
        className='dialog'
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className="dialogCon">
          <img src={dialogContent.image} alt={dialogContent.title} className="dialogImage" />
        </DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContent.description}
        </DialogContentText>
        <DialogContent className="dialogContent">
          <img src={dialogContent.image2} alt={dialogContent.title} className="dialogImg" />
          <img src={dialogContent.image3} alt={`${dialogContent.title} secondary`} className="dialogImg" />
          <img src={dialogContent.image4} alt={`${dialogContent.title} secondary`} className="dialogImg" />
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button className="dialogButton" onClick={handleClose} autoFocus sx={{color:'rgb(81, 0, 156)', fontFamily:'bai-bold', fontWeight:'bolder'}}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) ;
}

export default Test;
