import React from 'react';
import './Main.css';
import { Container } from '@mui/material';


function Hero() {
  return (
    <div id='home' className='hero-containering'>
        <Container className='hero-contain' sx={{marginTop:'11%'}}></Container>
        <div className='hero-contents'>
            <div className='image-contents'>
                <img src="\Img\Assets\header graphic2.png" alt="Uclash Logo" className="responsive-imgs" />
            </div>
            <div className='text-contents'>
                <h1 className='dark-league'>DARK LEAGUE</h1>
                <h3 className='studios'>STUDIOS</h3>               
                <p className='tag-line'>
                  Where <span className='her'>Heroes</span> Come<span className='to' >l</span>To <span className='light'>Light.</span>
                </p>
            </div>
        </div>
    </div>
  )
}

export default Hero;

