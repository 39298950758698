import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home/home';
// import Smart from './components/SGA/Smart';
import Pbae from './components/PBA/Pbae';
import Estudyante from './components/Estudyante/Estudyante';
import News1 from './components/News/NewsPage/News1';
import News2 from './components/News/NewsPage/News2';
import News3 from './components/News/NewsPage/News3';
import News4 from './components/News/NewsPage/News4';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route path='/home' element={<Home/>}/>
          <Route index element={<Home />}/>
          <Route path= '/ee' element = {<Estudyante/>}/>
          <Route path= '/pbae' element = {<Pbae/>}/>
          <Route path= '/news1' element = {<News1/>}/>
          <Route path= '/news2' element = {<News2/>}/>
          <Route path= '/news3' element = {<News3/>}/>
          <Route path= '/news4' element = {<News4/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
